/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "tachyons"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      image: file(relativePath: { eq: "img/author.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      copy: markdownRemark(frontmatter: { name: { eq: "homepage__bio" } }) {
        html
        frontmatter {
          title
        }
      }
    }
  `)
  const image = getImage(data.image)
  return (
    <>
      <div className="pv5 pa2 flex flex-wrap mw9 center justify-around items-center">
        <GatsbyImage
          image={image}
          alt="Linda van der Wal"
          className="w-100 mw6"
        />
        <div className="w-100 pa2 mw6 mv4">
          <span className="db f2 display dark-gray">
            {data.copy.frontmatter.title}
          </span>
          <div
            className="lh-copy f5 serif mt4"
            dangerouslySetInnerHTML={{ __html: data.copy.html }}
          />
          <Link
            to="/about"
            className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2"
          >
            Lees meer
          </Link>
        </div>
      </div>
    </>
  )
}

export default Bio
