import * as React from "react"
import Layout from "../common/layout"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "../homepage/components/hero"
import Card from "../homepage/components/card"
import About from "../homepage/components/about"
import Bio from "../homepage/components/bio"
import Seo from "../common/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      featuredPost: allMarkdownRemark(
        limit: 1
        sort: { order: DESC, fields: frontmatter___date }
        filter: { frontmatter: { type: { eq: "post" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              description: metaDescription
              slug
              postImage {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
      cards: allMarkdownRemark(
        skip: 1
        limit: 3
        sort: { order: DESC, fields: frontmatter___date }
        filter: { frontmatter: { type: { eq: "post" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              description: metaDescription
              slug
              postImage {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          description
        }
      }
    }
  `)

  let post = data.featuredPost.edges[0].node

  return (
    <Layout>
      <Seo
        title={"Home Page"}
        description={data?.site?.siteMetadata?.description}
      />
      <Hero
        title={post.frontmatter.title}
        image={post.frontmatter.postImage.childImageSharp.gatsbyImageData}
        to={post.frontmatter.slug}
        description={post.frontmatter.description}
      />
      <div className="flex flex-wrap center mw9 justify-around pb3">
        {data.cards.edges.map(({ node }, index) => (
          <Card
            title={node.frontmatter.title}
            image={node.frontmatter.postImage.childImageSharp.gatsbyImageData}
            to={node.frontmatter.slug}
            description={node.frontmatter.description}
            key={index}
          />
        ))}
      </div>
      <About />
      <Bio />
    </Layout>
  )
}

export default IndexPage
