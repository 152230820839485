/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const { site } = useStaticQuery(graphql`
    query AboutQuery {
      site {
        siteMetadata {
          homepageHeader
          homepageAbout
        }
      }
    }
  `)

  return (
    <>
      <div className="bg-washed-red flex flex-column justify-center items-center pa2 pv5">
        <h1 className="fw1 display db dark-gray f2 tc">
          {site.siteMetadata.homepageHeader}
        </h1>
        <p className="f4 serif mw7 tc lh-copy">
          {site.siteMetadata.homepageAbout}
        </p>
        <a
          href="mailto:lindavanderwal@quicknet.nl"
          className="mt3 db no-underline ph5 pv3 sans-serif near-white bg-dark-gray ttu tracked b hover-bg-mid-gray"
        >
          Ja ik wil!
        </a>
      </div>
    </>
  )
}

export default About
