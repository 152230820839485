/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "tachyons"

const Card = props => (
  <div className="w-100 mw6 pa2">
    <Link to={props.to}>
      <GatsbyImage image={props.image} alt="" className="w-100 h5" />
    </Link>
    <div className="pa2 display dark-gray f3 tc mb3">{props.title}</div>
    <div className="pa2 lh-copy serif tc mb3">{props.description}</div>
    <div className="pa2 flex justify-end serif">
      <Link to={props.to} className="dark-gray tracked ttu sans-serif f5">
        Lees meer &raquo;
      </Link>
    </div>
  </div>
)

export default Card
